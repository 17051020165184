import {team as teamUrl} from '#/js/config/api.json';

export default {
    getFirmTeams(params) {
        return _request({
            url: teamUrl.get_firm_teams,
            method: "GET",
            data: params
        });
    },
    getHistoryFirmTeams(params) {
        return _request({
            url: teamUrl.get_history_firm_teams,
            method: "POST",
            data: params
        });
    },
    getHistoryFirmTeamsForFinancial(params) {
        return _request({
            url: teamUrl.get_history_firm_teams_for_financial,
            method: "GET",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
    getTeamMembers(params) {
        return _request({
            url: teamUrl.get_team_members,
            method: "GET",
            data: params
        });
    },
    getHistoryTeamMembers(params) {
        return _request({
            url: teamUrl.get_history_team_members,
            method: "POST",
            data: params
        });
    },
    getHistoryTeamMembersForFinancial(params) {
        return _request({
            url: teamUrl.get_history_team_members_for_financial,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
}