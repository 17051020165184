<template>
    <el-dialog 
        title="常用开票信息" 
        :visible.sync="commonDialogVisible"
        width="500px"
        :lock-scroll="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="common-info-dialog"
        >
        <div class="info-cell-wrap" v-loading="loadingList">
            <div class="info-cell" v-for="(item, index) in commonInfoList" :key="'infoItem_'+index">
                <div class="cell-top">
                    <div class="company" v-text="item.companyName">北京字节跳动科技优先公司</div>
                    <div class="info-item max-ell" :title="'收件人：' + item.expressReceiver">收件人：<span v-text="item.expressReceiver">吴蒌</span></div>
                    <div class="info-item ml-40">电话：<span v-text="item.expressPhone">13637637367</span></div>
                    <div class="info-item">快递地址：<span v-text="item.expressAddress">深圳市南山区深圳湾科技生态园二区9栋B2座801-802</span></div>
                </div>
                <div class="cell-middle">
                    <div class="info-item max-ell" :title="'纳税人识别号：' + item.companyTaxpayerNo">纳税人识别号：<span v-text="item.companyTaxpayerNo">4211254534513</span></div>
                    <div class="info-item ml-40">电话：<span v-text="item.companyPhone">13637637367</span></div>
                    <div class="info-item" style="display:block;">注册地址：<span v-text="item.companyRegisterAddr">深圳市南山区深圳湾科技生态园二区9栋B2座801-802</span></div>
                    <div class="info-item max-ell" style="width:240px" :title="'银行账号：' + item.companyBankCardNo">银行账号：<span v-text="item.companyBankCardNo">2134565635367656</span></div>
                    <div class="info-item ml-40 max-ell1" :title="'银行：' + item.companyBankofDeposit">银行：<span v-text="item.companyBankofDeposit">中国银行</span></div>
                </div>
                <div class="cell-bottom">
                    <a href="javascript:;" class="select" @click="select(index)">选择</a>
                    <a href="javascript:;" class="remove" @click="remove(index)">删除</a>
                </div>
            </div>

            <div class="empty-data" v-if="commonInfoList.length == 0">
                <span class="empty-img "></span>
                <p>暂无数据</p>
            </div>
        </div>
        
    </el-dialog>
</template>

<script>
import floatingListService from '#/js/service/floatingListService.js';
export default {
    name: 'common-ticket-info',
	components: {
	},
	data() {
		return {
            commonDialogVisible: false,
            commonInfoList: [],
            loadingList: false,
        };
    },
    created() {
        this.requestCommonInfoList();
    },
	mounted() {},
	methods: {
        show() {
            this.commonDialogVisible = true;
        },
        requestCommonInfoList() {
            this.loadingList = true;
            floatingListService.getCommonInfoList().then(res => {
                this.commonInfoList = res || [];
            }).finally(() => {
                this.loadingList = false;
            });
        },
        select(index) {
            this.$emit('select-cb', this.commonInfoList[index]);
            this.commonDialogVisible = false;
        },
        remove(index) {
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteRequest(index);
            }).catch(() => {
                shortTips('已取消删除');
            });
        },
        deleteRequest(index) {
            this.loadingList = true;
            floatingListService.deleteCommonInfoList(this.commonInfoList[index].id).then(res => {
                shortTips('删除成功!');
                this.requestCommonInfoList();
            }).catch(() => {
                shortTips('网络请求错误，删除失败！');
            }).finally(() => {
                this.loadingList = false;
            });
        }
    }
}
</script>
<style lang="scss" scope>
.common-info-dialog{
     .el-dialog__body{
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
    .info-cell-wrap{
        margin: 10px 0 29px 0;
        max-height: 560px;
        overflow-y: auto;
        padding: 0 22px;
        .info-cell{
            height: auto;
            box-sizing: border-box;
            border: 1px solid #DADADA;
            margin-bottom: 10px;
            .cell-top{
                box-sizing: border-box;
                padding: 20px;
                border-bottom: 1px dashed #DADADA;
                .company{
                    font-size: 16px;
                    font-weight: bold;
                    color: #444444;
                    line-height: 53px;
                }
            }
            .cell-middle{
                box-sizing: border-box;
                padding: 20px 20px 20px;
            }
            .cell-bottom{
                box-sizing: border-box;
                padding: 0 20px 20px;
                text-align: right;
                .select{
                    color: #38BB9C;
                    margin-right: 20px;
                }
                .remove{
                    color: #666;
                }
            }
            .info-item{
                display: inline-block;
                color: #666;
                >span{
                    color: #333;
                }
            }
            .max-ell{
                max-width: 210px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: top;
            }
            .max-ell1{
                max-width: 110px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: top;
            }
            .ml-40{
                margin-left: 40px;
            }

        }

        .empty-data{
            text-align: center;
            padding-bottom: 100px;
            padding-top: 50px;
            .empty-img{
                display: inline-block;
                background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
                background-size: contain;
                width: 160px;
                height: 160px;
            }
            >p{
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }
    }
}
</style>