var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "常用开票信息",
        visible: _vm.commonDialogVisible,
        width: "500px",
        "lock-scroll": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": "common-info-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.commonDialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          staticClass: "info-cell-wrap",
        },
        [
          _vm._l(_vm.commonInfoList, function (item, index) {
            return _c(
              "div",
              { key: "infoItem_" + index, staticClass: "info-cell" },
              [
                _c("div", { staticClass: "cell-top" }, [
                  _c(
                    "div",
                    {
                      staticClass: "company",
                      domProps: { textContent: _vm._s(item.companyName) },
                    },
                    [_vm._v("北京字节跳动科技优先公司")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info-item max-ell",
                      attrs: { title: "收件人：" + item.expressReceiver },
                    },
                    [
                      _vm._v("收件人："),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(item.expressReceiver),
                          },
                        },
                        [_vm._v("吴蒌")]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "info-item ml-40" }, [
                    _vm._v("电话："),
                    _c(
                      "span",
                      { domProps: { textContent: _vm._s(item.expressPhone) } },
                      [_vm._v("13637637367")]
                    ),
                  ]),
                  _c("div", { staticClass: "info-item" }, [
                    _vm._v("快递地址："),
                    _c(
                      "span",
                      {
                        domProps: { textContent: _vm._s(item.expressAddress) },
                      },
                      [_vm._v("深圳市南山区深圳湾科技生态园二区9栋B2座801-802")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "cell-middle" }, [
                  _c(
                    "div",
                    {
                      staticClass: "info-item max-ell",
                      attrs: {
                        title: "纳税人识别号：" + item.companyTaxpayerNo,
                      },
                    },
                    [
                      _vm._v("纳税人识别号："),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(item.companyTaxpayerNo),
                          },
                        },
                        [_vm._v("4211254534513")]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "info-item ml-40" }, [
                    _vm._v("电话："),
                    _c(
                      "span",
                      { domProps: { textContent: _vm._s(item.companyPhone) } },
                      [_vm._v("13637637367")]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "info-item",
                      staticStyle: { display: "block" },
                    },
                    [
                      _vm._v("注册地址："),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(item.companyRegisterAddr),
                          },
                        },
                        [
                          _vm._v(
                            "深圳市南山区深圳湾科技生态园二区9栋B2座801-802"
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info-item max-ell",
                      staticStyle: { width: "240px" },
                      attrs: { title: "银行账号：" + item.companyBankCardNo },
                    },
                    [
                      _vm._v("银行账号："),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(item.companyBankCardNo),
                          },
                        },
                        [_vm._v("2134565635367656")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info-item ml-40 max-ell1",
                      attrs: { title: "银行：" + item.companyBankofDeposit },
                    },
                    [
                      _vm._v("银行："),
                      _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(item.companyBankofDeposit),
                          },
                        },
                        [_vm._v("中国银行")]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "cell-bottom" }, [
                  _c(
                    "a",
                    {
                      staticClass: "select",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.select(index)
                        },
                      },
                    },
                    [_vm._v("选择")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "remove",
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function ($event) {
                          return _vm.remove(index)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ]),
              ]
            )
          }),
          _vm.commonInfoList.length == 0
            ? _c("div", { staticClass: "empty-data" }, [
                _c("span", { staticClass: "empty-img" }),
                _c("p", [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }