// 签约主体
export const entityMap = [
    {
        label: '上海科锐福克斯人才顾问有限公司',
        value: 1,
    },
    {
        label: '长沙八爪网络科技有限公司',
        value: 2,
    },
    {
        label: '深圳八爪网络科技有限公司',
        value: 3,
    },
];

// 合同类别
export const contractTypeMap = [
    {
        label: '猎头',
        value: 1,
    },
    {
        label: 'RPO',
        value: 2,
    },
];

export const PaymentCategory = [
    {
        label: '默认',
        value: 0,
    },
    {
        label: '职级',
        value: 1,
    },
    {
        label: '薪资区间',
        value: 2,
    },
];


export const PeriodStartType = [
    {
        label: '入职后',
        value: 0,
    },
    {
        label: '开票后',
        value: 1,
    },
    {
        label: '过保后',
        value: 2,
    },
];


export const PeriodDateType = [
    {
        label: '自然日',
        value: 0,
    },
    {
        label: '工作日',
        value: 1,
    },
    {
        label: '自然月',
        value: 2,
    },
];

export const paymentMap = [
    {
        label: '全款',
        value: 0,
    },
    {
        label: '首尾款',
        value: 1,
    },
];
export const otherPaymentType = [
    {
        label: '职级范围',
        value: 1,
    },
    {
        label: '薪资范围',
        value: 2,
    },
];

export const operationType = [
    {
        label: '创建合同',
        value: 0,
    },
    {
        label: '更新合同',
        value: 1,
    },
    {
        label: '创建支付配置',
        value: 4,
    },
    {
        label: '更新支付配置',
        value: 5,
    },
    {
        label: '更新默认合同',
        value: 7,
    },
];

export const constractInfoMap = {
    "contractNumber": "合同编号",
    "customerSubject": "客户主体",
    "contractType": "合同类别",
    "signedUserId": "签订人",
    "signedDate": "签订日期",
    "effectiveStartTime": "合同有效时间",
    "signedEntity": "签约主体",
    "attachments": "合同附件",
    "description": "合同费率说明",
};

export const paymentKeysMap = {
    "paymentCategory": "付款方式",
    "jobGrade": "职级范围",
    "minAnnualSalary": "最小薪资范围",
    "maxAnnualSalary": "最大薪资范围",
    "overWarrantyPeriod": "保证期长度",
    "overWarrantyPeriodDateType": "保证期日期单位",
    "paymentMethodType": "付款方式",
    "firstPaymentProportion": "首款比例",
    "balancePaymentProportion": "尾款比例",

    "firstInvoicePeriodStartType": "开票时间类型",
    "firstInvoicePeriod": "开票时间长度",
    "firstInvoicePeriodDateType": "开票时间单位",
    "firstRefundPeriodStartType": "回款时间类型",
    "firstRefundPeriod": "回款时间长度",
    "firstRefundPeriodDateType": "回款时间单位",

    "balanceInvoicePeriodStartType": "尾款开票时间类型",
    "balanceInvoicePeriod": "尾款开票时间长度",
    "balanceInvoicePeriodDateType": "尾款开票时间单位",
    "balanceRefundPeriodStartType": "尾款回款时间类型",
    "balanceRefundPeriod": "尾款回款时间长度",
    "balanceRefundPeriodDateType": "尾款回款时间单位",
};

export const percentOptions = [
    // { label: '0%', value: 0, },
    { label: '10%', value: 10, },
    { label: '20%', value: 20, },
    { label: '30%', value: 30, },
    { label: '40%', value: 40, },
    { label: '50%', value: 50, },
    { label: '60%', value: 60, },
    { label: '70%', value: 70, },
    { label: '80%', value: 80, },
    { label: '90%', value: 90, },
    // { label: '100%', value: 100, },
];

export const invoiceEntityMap = {
    '1':"上海科锐福克斯人才顾问有限公司",
    '2':"长沙八爪网络科技有限公司",
    '3':"深圳八爪网络科技有限公司",
};